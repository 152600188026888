import type { Seo } from '~/types/api'

export default async function useSeo(slug: string): Promise<Ref<Seo>> {
  const { data } = await useAsyncData(
    `seo:${slug}`,
    () => useNuxtApp().$api.content.getSeo(slug),
    {
      default: () => ({}) as Seo,
      getCachedData: (key, nuxt): Seo =>
        nuxt.payload.data[key] || nuxt.static.data[key],
    },
  )

  return data
}
